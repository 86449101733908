import React from "react"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import CustomNavTitle from "../../../components/CustomNavTitle"
import DefaultLayoutSidebar from "../../../components/DefaultLayoutSidebar"

import illustration2x from "../../../images/illustrations/illustration-requirements-iut@2x.png"

import { theme } from "../../../styles/theme"

const IutNeonates = ({ location }) => {
  return (
    <Layout>
      <SEO title="Specific Requirements - Intrauterine transfusion (IUT) and neonates" />

      <CustomNavTitle
        title="Specific Requirements"
        destination="/requirements/cmv-negative-components/"
        location={location}
      />

      <DefaultLayoutSidebar>
        <section className="theme max-w-content">
          <h3 css={[theme.components.title, theme.components.titleRed]}>
            Intrauterine transfusion (IUT) and neonates
          </h3>

          <h4
            className="mt-gap-2"
            css={[theme.components.title, theme.components.titleSmall]}
          >
            Indication for CMV negative components
          </h4>
          <p>
            For all IUT and neonate transfusions, CMV negative cellular
            components should be provided.
          </p>

          <h4
            className="mt-gap-2"
            css={[theme.components.title, theme.components.titleSmall]}
          >
            Duration of requirement
          </h4>
          <p>
            At each episode and up to 28 days post expected date of delivery.
          </p>
        </section>
        <aside>
          <img src={illustration2x} alt="Illustration" />
        </aside>
      </DefaultLayoutSidebar>
    </Layout>
  )
}

export default IutNeonates
